/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { useCallback, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import React from 'react';
import { HubSpotFormProps } from 'apps/bayada/components/hub-spot-form/cms-hub-spot-form';
import { CmsImageProps, ICmsImage, Sys } from '@bayada/interfaces';
import { ServiceProps } from '@bayada/interfaces';
import { Document } from '@contentful/rich-text-types';
import Banner from '../banner/banner';
import { getOfficeLocations } from 'apps/bayada/services/office-service';
import { usePathname } from 'next/navigation';
import OfficesList from 'apps/bayada/app/find-an-office/office-components/offices-list';
import OfficeMap from 'apps/bayada/app/find-an-office/office-components/offices-map';
import { GoogleMapContextProvider } from '../../context/google-map-context-provider';
import { OfficeDetailsSection } from './office-details-section';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import {
  OfficeModel,
  mapOfficeAPI
} from '../../app/find-an-office/office-data-parser';
import { useAppContext } from 'apps/bayada/context/app-context';
import { getServiceArray } from 'apps/bayada/services/service-helper';
import { trimSpaces } from 'apps/bayada/utils/helper';

export type LocalOfficePageProps = {
  localOfficePage: OfficePageProps | null;
  office?: OfficeModel | null;
  serviceList?: ServiceProps[] | null;
  slug?: string[];
  sys?: Sys;
  navHeaderLogo?: CmsImageProps;
};

export interface OfficePageProps {
  internalName?: string;
  service?: ServiceProps;
  backgroundImage?: CmsImageProps | null;
  form?: HubSpotFormProps;
  getDirectionsLabel?: string;
  aboutUsLabel?: string;
  aboutContent?: Document;
  accreditationList?: ICmsImage[] | null;
  moreInformationLabel?: string;
  directorLabel?: string;
  aboutBayadaLabel?: string;
  aboutBayadaText?: Document | null;
  serviceAreasLabel?: string;
  servicesOfferedLabel?: string;
  visitOfficeButtonText?: string;
  officeTimings?: string[];
  officesNearLabel?: string;
  shareViaEmailLabel?: string;
  bannerDescription?: string;
  contactUsContent?: Document;
  formHeader?: string;
  companyName?: string;
  abbreviation?: string;
}

export interface SpecialityProps {
  fullName: string;
  icon: CmsImageProps;
  isCurrentService: boolean;
}

/**
 * Renders the Office Page component.
 *
 * @param {LocalOfficePageProps} props - The props containing data for rendering the Office Page.
 * @returns {JSX.Element} - The rendered Office Page component.
 */
export function OfficePage(props: LocalOfficePageProps) {
  const { serviceList, localOfficePage, slug, office, navHeaderLogo } =
    props || {};
  const { context } = useAppContext();
  const OfficeApibaseURL = context?.constants?.OfficeApibaseURL;
  const officeRadius = context.constants?.officeRadius;
  const slugArr = slug || [];
  const pathName = usePathname().split('/');
  const currentService: string = decodeURIComponent(pathName[1]);
  const alteredCurrSer = currentService
    ?.toLowerCase()
    ?.replace(/-/g, ' ')
    ?.replace(/&/g, '/');
  const currentOfficeId = Number(slugArr?.[slugArr?.length - 1]);
  const [offices, setOffices] = useState<OfficeModel[]>([]);
  const windowObj = typeof window !== 'undefined' ? window : null;

  /**
   * Fetches offices near a given location and updates the state with the fetched offices.
   *
   * @param {any} lat - The latitude of the location.
   * @param {any} long - The longitude of the location.
   * @returns {Promise<void>} - A promise that resolves once the offices are fetched and the state is updated.
   */
  const fetchAllOffices = useCallback(
    async (lat: any, long: any) => {
      if (lat && long) {
        const officeData = await getOfficeLocations(
          lat,
          long,
          officeRadius,
          OfficeApibaseURL
        );
        const mappedData = officeData?.Response?.map((office: any) => {
          return mapOfficeAPI(office);
        });
        const officesByService = mappedData
          ?.filter(Boolean)
          .filter((office: OfficeModel) => {
            return (
              office?.officeId != currentOfficeId &&
              office?.services?.find((service) =>
                service
                  ?.toLowerCase()
                  ?.includes(
                    trimSpaces(
                      localOfficePage?.service?.shortName
                    )?.toLowerCase()
                  )
              )
            );
          });
        const nearByOffices = officesByService?.slice(0, 4);

        setOffices(nearByOffices?.filter(Boolean));
      }
    },
    [localOfficePage?.service?.fullName]
  );

  useEffect(() => {
    fetchAllOffices(
      office?.mailingAddressLatitude,
      office?.mailingAddressLongitude
    );
  }, [office]);

  const servArr = getServiceArray(office, serviceList, alteredCurrSer);

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    '@id': '#CommunityHealth',
    name: office?.name,
    logo: `https:${navHeaderLogo?.imagelarge}`,
    legalName: 'BAYADA Home Health Care',
    image: localOfficePage?.backgroundImage?.src,
    description: localOfficePage?.aboutContent
      ? documentToPlainTextString(localOfficePage?.aboutContent as Document)
      : office?.name,
    faxNumber: office?.fax,
    address: {
      '@type': 'PostalAddress',
      streetAddress: office?.mailingAddress1,
      addressLocality: office?.mailingAddressCity,
      addressRegion: office?.mailingAddressState,
      postalCode: office?.mailingAddressZipCode,
      addressCountry: 'US'
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: office?.mailingAddressLatitude,
      longitude: office?.mailingAddressLongitude
    },
    url: windowObj && windowObj?.location?.href,
    telephone: office?.phone
  };

  return (
    <React.Fragment>
      <div id="office-banner">
        <Banner
          backgroundImage={localOfficePage?.backgroundImage}
          contentAlignment={'center'}
          internalName={''}
          heading={office?.name || ''}
          headingValue="h1"
          subHeading={''}
          licenseNumber={office?.licenseNumber}
          text={localOfficePage?.bannerDescription}
          cssClass="break-words"
          contentClass="w-full"
        />
      </div>
      <div className="py-12 md:py-20 w-full">
        {office && (
          <>
            <Container id="office-details-container" fixed className="w-full">
              <OfficeDetailsSection
                office={office}
                officePage={localOfficePage}
                servicesOffered={servArr}
              />
            </Container>
            {offices?.length != 0 && (
              <div className=" my-10" id="office-maps">
                <GoogleMapContextProvider>
                  <OfficeMap
                    offices={offices}
                    localOfficePage={localOfficePage}
                    serviceList={serviceList}
                    isLocalOfficePage={true}
                  />
                </GoogleMapContextProvider>
              </div>
            )}
            {/*office details table */}
            {offices?.length != 0 && (
              <Container id="nearby-office" fixed className="w-full">
                <h2
                  className="t-22-28 font-bold mb-8 font-frutiger color-ba-primary-black "
                  aria-label="nearby-label"
                >
                  {`Other ${trimSpaces(localOfficePage?.service?.fullName)} Offices`}
                </h2>
                <div className="grid  grid-cols-12 gap-3 ">
                  <React.Fragment>
                    <OfficesList
                      localOfficePage={localOfficePage}
                      offices={offices}
                      serviceList={serviceList}
                      isFindAnOfficePage={false}
                    />
                  </React.Fragment>
                </div>
              </Container>
            )}
          </>
        )}
      </div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
      />
    </React.Fragment>
  );
}

export default OfficePage;
